.app {
  color: #fff;
  font-size: calc(10px + 2vmin);
  background-color: #1d2129;
}

/* left panel */

.leftPanel {
  background: #15171c;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* header */

a.logo:hover {
  color: #558da8;
  text-decoration: none;
}

.networks {
  width: 180px;
  font-size: 26px;
}

.networks a {
  opacity: 0.8;
}

.networks a:hover {
  color: #558da8;
  opacity: 1;
}

/* badge */

a.badge {
  background: #1d2129;
  border-radius: 2px;
}

a.badge:hover {
  text-decoration: none;
  background: #558da8;
}

/* right panel */

/* about */

#about a {
  text-decoration: underline;
}

#about a:hover {
  color: #558da8;
}

/* contact form */

#contact input,
#contact textarea {
  border: 1px solid #fff;
  border-radius: 2px;
}

#contact button {
  background: #15171c;
  border-radius: 2px;
}

#contact button:hover {
  background: #558da8;
}

#contact input:focus-visible,
#contact textarea:focus-visible,
#contact button:focus-visible {
  box-shadow: 0 0 0 3px #558da8;
}
